<template>
	<v-card class="mx-auto" max-width="900" outlined>
		<v-row class="ma-0 pa-0 d-flex flex-row">
			<v-col cols="3" class="pa-0">
				<div class="image-wrapper d-flex align-center justify-center">
					<v-img
						:src="banner.publicURL"
						v-if="banner !== null"
					></v-img>

					<p v-else class="font-weight-bold">No Image Uploaded</p>
				</div>
			</v-col>
			<v-col cols="9" class="px-4 py-3">
				<div class="d-flex align-start justify-space-between">
					<h3 class="title" color="#5F615A" v-if="published">
						<a :href="url" target="_blank">{{ title }}</a>
					</h3>
					<h3 class="title" color="#5F615A" v-else>
						{{ title }}
					</h3>
					<StatusChips :is-published="published"></StatusChips>
				</div>
				<h4
					class="font-weight-light description mb-2"
					v-if="description"
				>
					{{ description }}
				</h4>
				<div class="d-flex flex-row align-start justify-space-between">
					<div class="d-flex align-start">
						<div class="d-flex flex-column">
							<a class="author"
								>{{ author.firstName }} {{ author.lastName }}</a
							>
							<span v-if="updatedAt" class="date">
								Updated on {{ dateFormat(updatedAt) }}
							</span>
						</div>
					</div>
				</div>
			</v-col>
		</v-row>

		<v-divider></v-divider>

		<!--Action Buttons-->
		<v-card-actions class="px-6 py-4 card-actions">
			<UnoEditButton
				v-if="$can('update', 'Blog')"
				:to="{ name: 'blog.edit', params: { id } }"
			></UnoEditButton>

			<UnoPublishButton
				v-if="$can('publish', 'Blog')"
				:isPublished="published"
				@click.native="toggleStatus()"
			></UnoPublishButton>

			<v-spacer></v-spacer>

			<UnoDeleteButton
				v-if="$can('delete', 'Blog')"
				:deleting="deleting"
				@confirm="deleteBlog()"
			></UnoDeleteButton>
		</v-card-actions>
	</v-card>
</template>

<script>
// ACTION BUTTONS
import UnoPublishButton from '@/components/Buttons/UnoPublishButton'
import UnoEditButton from '@/components/Buttons/UnoEditButton'
import UnoDeleteButton from '@/components/Buttons/UnoDeleteButton'
import StatusChips from '@/components/StatusChips'

export default {
	data() {
		return {
			deleting: false,
			published: this.isPublished,
		}
	},
	props: {
		id: {
			type: String,
			required: true,
		},
		title: {
			type: String,
			required: true,
		},
		description: {
			type: String,
		},
		isPublished: {
			type: Boolean,
			required: true,
		},
		author: {
			type: Object,
			required: true,
		},
		banner: {
			type: Object,
		},
		slug: {
			type: String,
			required: true,
		},
		publicPath: {
			type: String,
		},
		updatedAt: {
			type: String,
		},
	},
	components: {
		UnoEditButton,
		UnoPublishButton,
		UnoDeleteButton,
		StatusChips,
	},
	computed: {
		authorFullName() {
			let fullName = ''
			if (this.author.firstName) fullName += `${this.author.firstName} `

			if (this.author.lastName) fullName += `${this.author.lastName}`

			return fullName
		},
		url() {
			let domain = this.$store.getters['app/getCurrentProjectDomain']
			let blogURL = `http://${domain}${this.publicPath}`
			return blogURL
		},
	},
	methods: {
		editBlog(id) {
			this.$router.push({
				name: 'blog.edit',
				params: { id: id },
			})
		},
		deleteBlog() {
			this.deleting = true
			this.$store.dispatch('blog/deleteBlog', {
				blogId: this.id,
			})
		},
		toggleStatus() {
			const projectId = this.$store.state.app.projectId

			this.axios({
				method: 'post',
				url: `/${projectId}/blogs/toggle-status/${this.id}`,
			}).then((res) => {
				this.published = res.data.data.isPublished
				this.$store.dispatch('blog/getListingData')
			})
		},
		dateFormat(date) {
			const d = new Date(date)
			const day = parseInt(d.toLocaleString('en', { day: 'numeric' }))
			const month = d.toLocaleString('en', { month: 'short' })
			const year = d.toLocaleString('en', { year: '2-digit' })
			let suffix = ''
			if (day === 1) {
				suffix = 'st'
			} else if (day === 2) {
				suffix = 'nd'
			} else if (day === 3) {
				suffix = 'rd'
			} else {
				suffix = 'th'
			}
			return `${day}${suffix} ${month}, ${year}`
		},
	},
}
</script>
<style scoped>
.image-wrapper {
	overflow: hidden;
	border: 1px solid #eee;
	max-height: 200px !important;
	height: 100%;
}
.image-wrapper .v-responsive {
	height: 100% !important;
}
.title a {
	font-size: 21px !important;
	line-height: 1.4 !important;
	color: black !important;
	text-decoration: none !important;
}
.title a:hover {
	color: var(--blue) !important;
}
.description {
	font-size: 18px;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	overflow: hidden;
}
.author {
	font-size: 17px;
	font-weight: 400;
	color: var(--blue);
}
.author:hover {
	text-decoration: underline;
}
.date {
	font-size: 15px;
	font-weight: 400;
	color: #808080eb;
}
.v-chip {
	min-width: 121px !important;
}
</style>
