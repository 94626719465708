<template>
	<v-container v-if="$can('view', 'Blog')" fluid>
		<div v-if="$can('create', 'Blog')" class="text-right">
			<v-btn rounded color="primary" dark @click="createBlog()">
				+ Create Blog
			</v-btn>
		</div>

		<div v-if="blogs.length > 0" class="mt-4">
			<BlogCard
				v-for="blog in blogs"
				:key="blog.id"
				v-bind="blog"
			></BlogCard>
		</div>

		<NoData v-else></NoData>

		<Paginator
			:paginator="paginator"
			:url="getUrl()"
			@update="changePage($event)"
		></Paginator>
	</v-container>
</template>

<script>
import Paginator from '../../components/Paginator'
import BlogCard from './components/BlogCard'
import NoData from '../../components/NoData'
export default {
	data() {
		return {}
	},
	components: {
		BlogCard,
		NoData,
		Paginator,
	},
	created() {
		this.$store.dispatch('blog/getListingData')
	},
	destroyed() {
		this.$store.commit('blog/clearState')
	},
	computed: {
		blogs: {
			get() {
				return this.$store.state.blog.blogs
			},
			set(value) {
				this.updateBlogs(value)
			},
		},
		paginator: {
			get() {
				return this.$store.state.blog.paginator
			},
			set(value) {
				this.updatePaginator(value)
			},
		},
	},
	methods: {
		updateBlogs(value) {
			this.$store.commit('blog/updateBlogs', value)
		},
		updatePaginator(value) {
			this.$store.commit('blog/updatePaginator', value)
		},
		getUrl() {
			const projectId = this.$store.state.app.projectId
			let url = `/${projectId}/blogs`
			return url
		},
		changePage(data) {
			this.blogs = data.data
			this.paginator = data.paginator
		},
		createBlog() {
			this.$router.push({
				name: 'blog.create',
			})
		},
	},
}
</script>
